<template>
    <Card title="Responsável pela Área Restrita" v-if="registering">
        <form class="my-9 divide-y" @keyup.enter="next">
            <div class="grid grid-cols-12 mb-7 gap-5">
                <Input label="Nome Completo" :autofocus="true" :name="['manager', 'NomeCompleto', 'managerNome']"
                    :value="technicalForm.manager.NomeCompleto" :validation="formValidation.manager.NomeCompleto"
                    @change="changeField" class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="E-mail Principal" :name="['manager', 'Email', 'managerEmail']" @change="changeField"
                    :value="technicalForm.manager.Email" :validation="formValidation.manager.Email"
                    class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="CPF" :name="['manager', 'CPF', 'managerCPF']" mask="###.###.###-##"
                    :value="technicalForm.manager.CPF" :validation="formValidation.manager.CPF" @change="changeField"
                    class="col-span-12 sm:col-span-6 lg:col-span-3" />
                <Input label="Celular" :name="['manager', 'Celular', 'managerCelular']" mask="(##) #####-####"
                    type="phone" @change="changeField" :value="technicalForm.manager.Celular"
                    :validation="formValidation.manager.Celular" class="col-span-12 sm:col-span-6 lg:col-span-3" />
            </div>

            <div class="grid grid-cols-12 mb-7 gap-5">
                <h1
                    class="inline-block text-3xl col-span-12 md:col-span-6 lg:col-span-8 mt-5 font-bold text-gray-900 tracking-tight">
                    Responsável Técnico pelo Uso das Soluções</h1>
                <div class="col-span-12 md:col-span-6 lg:col-span-4 self-end">
                    <div class="flex justify-between">
                        <h1 class="inline-block text-md self-end md:ml-auto mt-5 text-gray-900 tracking-tight">Usar
                            dados do responsável da área? </h1>
                        <Checkbox class="ml-3 mt-2" :active="lock" @check="lockFields" />
                    </div>
                </div>
                <Input label="Nome Completo" :disabled="lock" :name="['technician', 'NomeCompleto', 'technicianNome']"
                    :value="technicalForm.technician.NomeCompleto"
                    :validation="!this.lock ? formValidation.technician.NomeCompleto : ''" @change="changeField"
                    class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="E-mail Principal" :disabled="lock" :name="['technician', 'Email', 'technicianEmail']"
                    @change="changeField"
                    :value="technicalForm.technician.Email"
                    :validation="!this.lock ? formValidation.technician.Email : ''"
                    class="col-span-12 md:col-span-6 lg:col-span-3" />
                <Input label="CPF" :disabled="lock" :name="['technician', 'CPF', 'technicianCPF']" mask="###.###.###-##"
                    :value="technicalForm.technician.CPF"
                    :validation="formValidation.technician.CPF" @change="changeField"
                    class="col-span-12 sm:col-span-6 lg:col-span-3" />
                <Input label="Celular" :disabled="lock" :name="['technician', 'Celular', 'technicianCelular']"
                    mask="(##) #####-####" type="phone" @change="changeField"
                    :value="technicalForm.technician.Celular"
                    :validation="!this.lock ? formValidation.technician.Celular : ''"
                    class="col-span-12 sm:col-span-6 lg:col-span-3" />
            </div>
        </form>
        <div class="flex">
            <Button @click="back" :icon="backIcon" :loading="loading" :class="{ 'custom-buttom-disabled': loading }" iconPosition="left" label="Voltar"
                class="ml-auto hover:bg-gray-300" />
            <PrimaryButton @click="next" :icon="nextIcon" iconPosition="right" :loading="loading" label="Prosseguir"
                :class="{ 'custom-buttom-disabled': loading }"
                class="ml-5 next" />
        </div>
    </Card>
</template>

<script>
import Card from '../../../ui/Card.vue'
import PrimaryButton from '../../../buttons/PrimaryButton'
import Button from '../../../buttons/Button'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Input from '../../../input/Input'
import Checkbox from '../../../input/Checkbox'
import utils from '@/utils'
import api from "@/services/api";

export default {
    name: 'TechnicalData',
    props: ['data', 'clientId', 'preRegister'],
    components: {
        Card,
        PrimaryButton,
        Button,
        Input,
        Checkbox
    },
    data() {
        return {
            representatives: [],
            registering: true,
            newRegister: true,
            loading: false,
            nextIcon: faChevronRight,
            backIcon: faChevronLeft,
            lock: false,
            technicalForm: {
                manager: {
                    NomeCompleto: '',
                    Email: '',
                    Celular: '',
                    CPF: ''
                },
                technician: {
                    NomeCompleto: '',
                    Email: '',
                    Celular: '',
                    CPF: ''
                }
            },
            formValidation: {
                manager: {
                },
                technician: {
                }
            },
            UFs: [],
            cities: [],
            date: null
        }
    },
    mounted() {
        if (this.data) {
            if (this.data.tecnico) {
                this.newRegister =  !this.data.tecnico.uuid;
                this.technicalForm.technician = this.data.tecnico
                this.technicalForm.technician.Celular = utils.applyMask('Celular', this.technicalForm.technician.Celular)
                this.technicalForm.technician.CPF = utils.applyMask('CPF', this.technicalForm.technician.CPF)
            }
            if(this.data.responsavel) {
                this.newRegister = !this.data.responsavel.uuid;
                this.technicalForm.manager = this.data.responsavel
                this.technicalForm.manager.Celular = utils.applyMask('Celular', this.technicalForm.manager.Celular)
                this.technicalForm.manager.CPF = utils.applyMask('CPF', this.technicalForm.manager.CPF)
            }
            if (!this.newRegister && this.technicianHasTheDataThanManager()) {
                this.lockFields()
            }
            Object.keys(this.technicalForm.technician).forEach(field => {
                utils.validate(
                    field,
                    this.technicalForm['technician'],
                    this.formValidation['technician'],
                    this.clientType
                );
            })
            Object.keys(this.technicalForm.manager).forEach(field => {
                utils.validate(
                    field,
                    this.technicalForm['manager'],
                    this.formValidation['manager'],
                    this.clientType
                );
            })
        }
        if (!this.data?.responsavel?.Email && !this.data?.tecnico?.Email && this.newRegister && this.preRegister) {
            this.technicalForm.manager.Email = this.preRegister.email
            this.technicalForm.technician.Email = this.preRegister.email
        }
    },
    methods: {
        changeField(index, value) {
            const form = index[0]
            const field = index[1]
            this.technicalForm[form][field] = value
            if (this.lock) {
                this.fillTechnicianDataWithManagerData()
            }
            utils.validate(
                field,
                this.technicalForm[form],
                this.formValidation[form],
                this.clientType
            );
        },

        async next() {
            this.loading = true

            this.formValidation = {
                manager: {
                },
                technician: {
                }
            },

            Object.keys(this.technicalForm.manager).forEach(field => {
                field = field.replaceAll("Validated", "")

                if (!this.technicalForm.manager[field]) {
                    this.formValidation.manager[field] = 'Este campo é obrigatório.'
                }

                utils.validate(
                    field,
                    this.technicalForm.manager,
                    this.formValidation.manager,
                    this.clientType
                );
            })


            if (!this.lock) {
                Object.keys(this.technicalForm.technician).forEach(field => {
                    field = field.replaceAll("Validated", "")


                    if (!this.technicalForm.technician[field]) {
                        this.formValidation.technician[field] = 'Este campo é obrigatório.'
                    }

                    utils.validate(
                        field,
                        this.technicalForm.technician,
                        this.formValidation.technician,
                        this.clientType
                    );
                })

                if (Object.keys(this.formValidation.technician).length > 0) {
                    this.loading = false
                    return false
                }
            } else {
                this.fillTechnicianDataWithManagerData()
            }

            if (Object.keys(this.formValidation.manager).length > 0) {
                this.loading = false
                return false
            }

            if(this.newRegister) await this.createResponsibles();
            else await this.updateResponsibles();

            this.$emit('next', this.technicalForm)
        },

        back() {
            this.$emit('back', this.technicalForm)
        },

        lockFields() {
            this.lock = !this.lock
            if (this.lock) {
                this.fillTechnicianDataWithManagerData()
            }
            Object.keys(this.technicalForm.manager).forEach(field => {
                utils.validate(
                    field,
                    this.technicalForm['manager'],
                    this.formValidation['manager'],
                    this.clientType
                );
            })
            Object.keys(this.technicalForm.technician).forEach(field => {
                utils.validate(
                    field,
                    this.technicalForm['technician'],
                    this.formValidation['technician'],
                    this.clientType
                );
            })
        },

        technicianHasTheDataThanManager() {
            return this.technicalForm.manager.NomeCompleto == this.technicalForm.technician.NomeCompleto &&
                this.technicalForm.manager.Email == this.technicalForm.technician.Email &&
                this.technicalForm.manager.CPF == this.technicalForm.technician.CPF &&
                this.technicalForm.manager.Celular == this.technicalForm.technician.Celular
        },

        fillTechnicianDataWithManagerData() {
            const technicianUUID = this.technicalForm.technician.uuid
            const managerData = utils.deepClone(this.technicalForm.manager)
            this.technicalForm.technician = managerData
            if (technicianUUID) {
                this.technicalForm.technician.uuid = technicianUUID
            }
        },

        async createResponsibles() {
            this.loading = true
            const responsavelResponse = await api.post(`/responsible`, {
              ...utils.formatResponsibleToDatabase(this.technicalForm.manager),
              relationType: 'software-houses',
              relationId: this.clientId,
              role: 'responsavel'
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            }).catch( () => {     
              this.$swal({
                title: "Erro!",
                icon: "error",
                allowOutsideClick: true,
                text: "Houve um problema ao registrar responsável técnico, tente novamente.",
                confirmButtonText: "Ok",
              })
              this.loading = false
            })
            const tecnicoResponse = await api.post(`/responsible`, {
                ...utils.formatResponsibleToDatabase(this.technicalForm.technician),
                relationType: 'software-houses',
                relationId: this.clientId,
                role: 'tecnico',
                },
                {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }).catch( () => {  
                this.$swal({
                    title: "Erro!",
                    icon: "error",
                    allowOutsideClick: true,
                    text: "Houve um problema ao registrar responsável, tente novamente.",
                    confirmButtonText: "Ok",
                })
                this.loading = false
            })
            this.loading = false
            const { tecnico } = utils.formatResponsibleToForm([responsavelResponse.data, tecnicoResponse.data])
            this.technicalForm.technician = tecnico.tecnico
            this.technicalForm.manager = tecnico.responsavel
        },

        async updateResponsibles() {
            this.loading = true
            const responsavelResponse = await api.patch(`/responsible/${this.technicalForm.manager.uuid}`, {
              ...utils.formatResponsibleToDatabase(this.technicalForm.manager),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            }).catch( () => {     
              this.$swal({
                title: "Erro!",
                icon: "error",
                allowOutsideClick: true,
                text: "Houve um problema ao atualizar responsável técnico, tente novamente.",
                confirmButtonText: "Ok",
              })
              this.loading = false
            })
            const tecnicoResponse = await api.patch(`/responsible/${this.technicalForm.technician.uuid}`, {
              ...utils.formatResponsibleToDatabase(this.technicalForm.technician),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            }).catch( () => {  
              this.$swal({
                title: "Erro!",
                icon: "error",
                allowOutsideClick: true,
                text: "Houve um problema ao atualizar responsável, tente novamente.",
                confirmButtonText: "Ok",
              })
              this.loading = false
            })
            this.loading = false
            const { tecnico } = utils.formatResponsibleToForm([responsavelResponse.data, tecnicoResponse.data])
            this.technicalForm.manager = tecnico.responsavel;
            this.technicalForm.technician = tecnico.tecnico
        },
      }
}
</script>