<style>
.cell {
  padding: 5px;
  min-height: 45px;
  font-size: 12px;
}
</style>

<template>
  <Card title="Dados do Contrato">
    <p class="mt-3 text-lg">
      Com base nos dados informados geramos um contrato que deverá ser assinado
      eletronicamente:
    </p>
    <div>
      <div class="block md:flex justify-center gap-5">
        <Modal id="data">
          <template v-slot:label>
            <div class="
                card-link
                w-100
                bg-white
                my-10
                shadow-xl
                rounded-lg
                p-3
                text-left
                border
              ">
              <div class="grid">
                <FontAwesomeIcon class="m-auto mb-3 text-4xl" :icon="userIcon" />
                <div class="text-base">Confirmar os dados</div>
              </div>
            </div>
          </template>

          <template v-slot:modal>
            <ConfirmFormData :data="data" :clientType="clientType" />
          </template>
        </Modal>
        <div @click="download" class="
            card-link
            w-100
            bg-white
            my-10
            shadow-xl
            rounded-lg
            p-3
            text-left
            border
          ">
          <div class="grid">
            <FontAwesomeIcon class="m-auto mb-3 text-4xl" :icon="downloadIcon" />
            <div class="text-base">Baixar modelo do contrato</div>
          </div>
        </div>
      </div>

      <div class="flex mb-5">
        <PrimaryButton v-if="!signable" @click="createDocument" :icon="nextIcon" iconPosition="right" :loading="loading"
          label="Gerar contrato usando o Speedsign" class="w-22 m-auto" />
        <PrimaryButton v-else-if="signable && !signed && !signing" @click="sign" :icon="nextIcon" iconPosition="right"
          :loading="loading || submitLoading" label="Verificar status do documento" 
          :class="(loading || submitLoading ) && !isTesting()
          ? 'custom-buttom-disabled'
          : ''
          " class="w-22 m-auto" />
        <div v-else-if="!signed" class="text-primary m-auto text-lg font-bold">
          <FontAwesomeIcon class="mr-4 self-center animate-spin text-primary" :icon="spinner" />
          Aguardando resposta do Speedsign
        </div>
        <div v-if="signed" class="text-primary m-auto text-lg font-bold">
          Documento assinado com sucesso!
        </div>
      </div>
      <div class="flex w-100">
        <div class="flex mx-auto mb-4">
          <Checkbox name="agree" :aligned="true" class="mr-3" :active="agree" @check="privacy" />
          <p class="self-center text-base mt-2">
            Aceito os termos de uso e as
            <a href="/documents/tecnopay_termos_de_uso_da_plataforma_030624.pdf" class="link font-bold"
              target="_blank">politicas
              de
              privacidade</a>
          </p>
        </div>
      </div>
    </div>

    <div v-if="clientType !== 'software-houses'" class="text-lg w-2/3 my-6 m-auto text-center">
      <b class="text-xl" v-if="clientType !== 'software-houses'">Acompanhamento</b>
      <br>
      <embed class="mx-auto mt-6" v-if="loadingData === true" width="75" height="75" src="/colorful-loading.gif" />
      <h1 class="font-medium" v-if="loadingData === true">Carregando...</h1>
      <step-progress v-if="!this.loadingData" :progress="stepProgress" :errorProgress="errorProgress"></step-progress>
      <br><br><br>
    </div>
    <p v-if="!loadingData" class="text-lg w-2/3 my-6 m-auto text-center">
      <b>{{ this.messageStatus }}: </b>{{ this.message }}
    </p>
    <div class="flex">
      <Button @click="back" 
        :class="(loading || submitLoading ) && !isTesting()
        ? 'custom-buttom-disabled'
        : ''
        "
        :loading="loading || submitLoading"
        :icon="backIcon" 
        iconPosition="left" 
        label="Voltar" 
        class="ml-auto hover:bg-gray-300" 
      />
      <PrimaryButton v-if="clientType === 'software-houses' && !this.loadingData" 
        :class="(!signed || !agree || submitLoading ) && !isTesting()
            ? 'custom-buttom-disabled'
            : ''
            "
        @click="next" :icon="nextIcon" iconPosition="right"
        :loading="submitLoading || loading" :label="'Solicitar cadastro'" class="ml-5 next" />

      <div>
        <div>
          <PrimaryButton @click="next"
            v-if="clientType !== 'software-houses' && materaAccountIdExists !== false && stepProgress === 4" :class="(!signed || !agree || submitLoading || materaAccountIdExists === false) && !isTesting()
                  ? 'custom-buttom-disabled'
                  : ''
                  " :icon="nextIcon" iconPosition="right" :loading="loading || submitLoading" 
            label="Finalizar" class="
              custom-primary-button
              text-white
              rounded-lg
              cursor-pointer
              py-2
              px-5
              ml-5
              next
            " />
          <div
            v-if="clientType !== 'software-houses' && !this.loadingData && this.$store.state.materaAccountId && stepProgress === 3"
            @click="verifyStatus" :loading="loading"  :class="{ 'custom-buttom-disabled': disableVerifyButton }"
            class="custom-primary-button text-white rounded-lg py-2 px-5 ml-5">
            <span v-if="disableVerifyButton">Verificar Situação ({{ remainingTime }}s)</span>
            <span v-else>Verificar Situação</span>
          </div>
          <PrimaryButton
            v-if="clientType !== 'software-houses' && !this.loadingData && (stepProgress === 1 || stepProgress === 2)"
            :class="{ 'custom-buttom-disabled': loading || !signed }" @click="createMateraAccount" :icon="nextIcon"
            iconPosition="right" :loading="loading" label="Solicitar Cadastro" class="ml-none sm:ml-4 sm:mb-none" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import PrimaryButton from "../../../buttons/PrimaryButton";
import Button from "../../../buttons/Button";
import Modal from "../../../ui/Modal";
import Card from "../../../ui/Card";
import stepProgress from "../../../progress/StepProcess"
import {
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faUser,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import api from "@/services/api";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ConfirmFormData from "../../ConfirmFormData.vue";
import Checkbox from "../../../input/Checkbox";
import { UAParser } from 'ua-parser-js'
import utils from "@/utils";

export default {
  name: "RepresentativeData",
  props: [
    "CNPJ",
    "data",
    "clientType",
    "companyCNPJ",
    "submitLoading",
    "materaIsIdle",
    "estabelecimentoStatus",
    "showLoadingModal",
    "protocolo",
    "clientId",
  ],
  components: {
    Card,
    Checkbox,
    PrimaryButton,
    Button,
    Modal,
    FontAwesomeIcon,
    ConfirmFormData,
    stepProgress
  },
  data() {
    return {
      signable: false,
      signed: false,
      signing: false,
      loading: false,
      loadingData: false,
      spinner: faCircleNotch,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      userIcon: faUser,
      materaAccountIdValue: null,
      date: null,
      pdf: null,
      activeColor: '#ef4444',
      contract: null,
      downloadIcon: faDownload,
      finalizado: false,
      loadingContract: false,
      agree: false,
      materaAccountIdExists: false,
      disableVerifyButton: false,
      remainingTime: 0,
      timer: null,
      stepProgress: 1,
      errorProgress: false,
      messageStatus: "Situação",
      message: 'Foi enviado um e-mail para que seja assinado o contrato de parceria comercial. Utilize um terminal que tenha acesso ao certificado digital da sua empresa.'
    };
  },
  async created() {
    this.loadingData = true
    this.loadingContract = true;
    api
      .get(`/contracts/${this.protocolo}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then( async(response) => {
        this.signed = response.data.isFinished;
        this.contrato = response.data.uuid;

        this.signable = true;

        this.finalizado = response.data.isFinished ?? false;
        
        if (!this.contrato) this.createDocument();
        if (this.$store.state.materaAccountId && this.signed) {
          await this.getMateraAccount(this.companyCNPJ, this.$store.state.materaAccountId)
        }
      })
      .catch((response) => {
        this.createDocument();
        this.$emit("registerLastRequest", response);
      })
      .finally(() => {
        this.loadingData = false
      })

    this.loadingContract = false;
  },
  watch: {
    signed: {
      handler: function () {
        this.updateStepProgress();
      },
      deep: true
    }
  },

  methods: {
    isTesting() {
      return process.env.VUE_APP_NODE_ENV == "test" ? true : false;
    },

    next() {
      this.$emit("next", {
        contratoId: this.contrato,
        softwareHouseCnpj: this.CNPJ,
        cnpj: this.companyCNPJ,
        accountId: this.$store.state.materaAccountId
      });
    },

    back() {
      this.$emit("back");
    },
    updateStepProgress () {
      if(!this.signed) {
        this.stepProgress = 1
        this.message = 'Foi enviado um e-mail para que seja assinado o contrato de parceria comercial. Utilize um terminal que tenha acesso ao certificado digital da sua empresa.'
      }
      if(this.signed) {
        this.stepProgress = 2
        this.message = 'Para prosseguir, clique no botão "Solicitar Cadastro"'
      }
    },

    async verifyStatus() {
      this.disableVerifyButton = true;

      this.remainingTime = 60;

      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.disableVerifyButton = false;
          clearInterval(this.timer);
        }
      }, 1000);

      const alreadyExistsATecnoAccount = await this.alreadyExistsATecnoAccount(this.companyCNPJ);
      if (alreadyExistsATecnoAccount) {
        this.stepProgress = 4
        this.errorProgress = false
        this.messageStatus = 'Situação'
        this.message = 'Sua conta foi sincronizada com o PlugPIX, seu cadastro está finalizado e agora você pode realizar movimentações financeiras usando sua chave PIX.'
        this.materaAccountIdExists = true
        this.loading = false;

        this.$swal({
          title: "Tudo Certo!",
          icon: "success",
          text: "Sua conta foi sincronizada com o PlugPIX, seu cadastro está finalizado e agora você pode realizar movimentações financeiras usando sua chave PIX.",
          confirmButtonText: "Finalizar",
          allowOutsideClick: false,
        })
        .then(() => {
          this.$router.push(`/`);
        })
        .catch((response) => {
          this.lastRequest = response;
        })
        return;
      }
      await this.verifyMateraAccountStatus(this.companyCNPJ, this.$store.state.materaAccountId);
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
    
    download() {
      api({
        method: "get",
        url: "/contracts",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          const objectUrl = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          window.open(objectUrl, "_blank");
        })
        .catch((response) => {
          this.$emit("registerLastRequest", response);
        });
    },

    createDocument() {
      this.loading = true

      api
        .post(
          "/speedsign/contracts",
          {
            filename: "teste",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.signable = true;
          this.contrato = response.data.contratoId;
          this.loading = false
        })
        .catch((response) => {
          this.loading = false

          this.$emit("registerLastRequest", response);
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text:
              "Houve um problema ao enviar os dados do contrato, tente novamente.",
            confirmButtonText: "Ok",
          });
        });

      this.signable = true;
    },

    async createMateraAccount() {
      this.loading = true;
      const parser = new UAParser(navigator.userAgent);
      const userAgentData = parser.getResult();
      let position;
      let sweetAlertTimeout;
      const geolocationPermission = await utils.getPermissionByName('geolocation');
      const sweetAlertData = {
        title: "Aviso!",
        icon: 'warning',
        text: "Necessário permissão para acessar a localização, por favor, verifique se o acesso a localização está ativada.",
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: false,
      }
      if (!geolocationPermission) {
        sweetAlertTimeout = setTimeout(() => {
          this.$swal(sweetAlertData);
        }, 2000)
      } else if (geolocationPermission === 'prompt') {
        this.$swal(sweetAlertData);
      }

      try {
        position = await utils.getCurrentPosition();
        if (sweetAlertTimeout)
          clearTimeout(sweetAlertTimeout);
        this.$swal.close()
      } catch(error) {
        if (sweetAlertTimeout)
          clearTimeout(sweetAlertTimeout);
        this.$swal.close()
        this.$swal({
          title: "Aviso!",
          icon: 'warning',
          text: "Necessário permissão para acessar a localização, por favor, verifique se o acesso a localização está ativada e tente novamente.",
          allowOutsideClick: false,
          showConfirmButton: false,
          showCancelButton: false,
        });
        return;
      }

      const userAgent = {}
      if (userAgentData.browser?.name) {
        userAgent.browser = {
          name: userAgentData.browser.name,
          version: userAgentData.browser.version
        }
      }
      if (userAgentData.os?.name) {
        userAgent.os = {
          name: userAgentData.os.name,
          version: userAgentData.os.version
        }
      }
      if (userAgentData.device?.vendor) {
        userAgent.device = {
          vendor: userAgentData.device.vendor,
          model: userAgentData.device.model
        }
      }

      let materaAccount = await api
        .post(
          `/partners/contract/company/create-matera-account`,
          {
            cnpj: this.companyCNPJ,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            userAgent
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .catch((response) => {
          this.lastRequest = response;
          this.cadastrando = true;
          this.loading = false;
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao cadastrar a conta, tente novamente.",
            confirmButtonText: "Ok",
          })
        });
      this.loading = false;
      this.materaAccountIdValue = materaAccount.data.accountId
      this.$store.commit('updateMateraAccountId', materaAccount.data.accountId)
      this.$store.commit('updateMateraAccountStatus', materaAccount.data.status)

      switch (materaAccount.data.status) {
        case "creating": {
          this.stepProgress = 3
          this.loading = false;
          this.messageStatus = 'Situação'
          this.materaAccountIdExists = true
          this.errorProgress = false
          this.message = 'Aguardando aprovação da conta. Você será notificado por e-mail quando a aprovação da conta for concluída. Esse processo pode demorar de 15 minutos a 6 horas.'
          break;
        }
        case "regular": {
          this.stepProgress = 4
          this.loading = false;
          this.messageStatus = 'Situação'
          this.materaAccountIdExists = true
          this.errorProgress = false
          this.message = 'Conta aprovada com sucesso! Clique em "Finalizar" para concluir o cadastro no TecnoPay.'
          break;
        }
        case "rejected": {
          this.loading = false;
          this.messageStatus = 'Erro'
          this.errorProgress = true
          this.message = 'Conta rejeitada. Por favor, verifique os dados e documentos enviados. Em breve, você receberá um e-mail detalhando as razões da rejeição e as próximas etapas a serem seguidas.'
          break;
        }
      }
    },

    async getMateraAccount(cnpj, accountId) {
      const materaResponse = await api
        .get(`/matera/` + cnpj + "/" + accountId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.status === 'regular') {
            this.stepProgress = 4
            this.errorProgress = false
            this.messageStatus = 'Situação'
            this.message = 'Conta aprovada com sucesso! Clique em "Prosseguir" para concluir o cadastro no TecnoPay'
            this.materaAccountIdExists = true
          }

          if (response.data.status === 'creating') {
            this.stepProgress = 3
            this.messageStatus = 'Situação'
            this.errorProgress = false
            this.message = 'Aguardando aprovação da conta. Você será notificado por e-mail quando a aprovação da conta for concluída.'
          }

          if (response.data.status === 'rejected') {
            this.messageStatus = 'Erro'
            this.stepProgress = 3
            this.errorProgress = true
            this.message = 'Conta rejeitada. Por favor, verifique os dados e documentos enviados. Em breve, você receberá um e-mail detalhando as razões da rejeição e as próximas etapas a serem seguidas.'
          }
        })
        .catch(() => {
          return null;
        });

      return materaResponse;
    },

    async verifyMateraAccountStatus(cnpj, accountId) {
      this.loading = true;
      const materaResponse = await api
        .get(`/partners/contract/company/verify-matera-account-status/` + cnpj + "/" + accountId , {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.$store.commit('updateMateraAccountStatus', response.data.status)

          if (response.data.status === 'regular') {
            this.stepProgress = 4
            this.errorProgress = false
            this.messageStatus = 'Situação'
            this.message = 'Conta aprovada com sucesso! Clique em "Finalizar" para concluir o cadastro no TecnoPay'
            this.materaAccountIdExists = true
          }

          if (response.data.status === 'creating') {
            this.stepProgress = 3
            this.messageStatus = 'Situação'
            this.errorProgress = false
            this.message = 'Aguardando aprovação da conta. Você será notificado por e-mail quando a aprovação da conta for concluída.'
          }

          if (response.data.status === 'rejected') {
            this.messageStatus = 'Erro'
            this.stepProgress = 3
            this.errorProgress = true
            this.message = 'Conta rejeitada. Por favor, verifique os dados e documentos enviados. Em breve, você receberá um e-mail detalhando as razões da rejeição e as próximas etapas a serem seguidas.'
          }

          this.loading = false;
        })
        .catch(() => {
          this.disableVerifyButton = false;
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao verificar o status da conta, tente novamente.",
            confirmButtonText: "Ok",
          })

          this.loading = false;
        });

      return materaResponse;
    },

    async alreadyExistsATecnoAccount(companyCnpj) {
      const commercialEstablishmentAvailability = await api.get(`/shop/commercial-establishments/availability/${companyCnpj}`)
      return !commercialEstablishmentAvailability.data.disponivel
    },

    async sign() {
      this.loading = true
      this.signing = true;

      const response = await api({
        method: "get",
        url: "/speedsign/contracts/" + this.contrato,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).catch((response) => {
        this.$emit("registerLastRequest", response);
      });

      if (response.data.finalizado) {
        await api
          .put(
            "/speedsign/contracts/" + this.contrato,
            {
              finalizado: true,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },

            }
          )
          .catch((response) => {
            this.$emit("registerLastRequest", response);
          });
        this.$swal({
          title: "Sucesso!",
          icon: "success",
          text: "Documento assinado com sucesso.",
          confirmButtonText: "Ok",
        });
        this.signed = true;
        this.finalizado = true;
      } else {
        this.$swal({
          title: "Alerta!",
          icon: "warning",
          text: "Documento ainda não foi assinado.",
          confirmButtonText: "Ok",
        });
        this.signed = false;
      }

      this.loading = false
      this.signing = false;
    },

    privacy() {
      this.agree = !this.agree;
    }
  },
}
</script>
