<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div>
    <div class="w-full h-24 bg-primary flex px-8 md:px-12">
      <div class="xl:container flex justify-between self-center m-auto w-full">
        <img src="/logo-white.png" width="200" height="50" />
        <Modal label="visualizar Contrato" id="help" :hide="showHelpModal">
          <template v-slot:label>
            <div class="cursor-pointer text-white hidden md:block text-lg">Precisa de ajuda?</div>
            <div class="text-white block md:hidden text-2xl">
              <FontAwesomeIcon :icon="questionCircle" />
            </div>
          </template>
          <template v-slot:modal>
            <div class="p-8">
              <h1
                class="
                  inline-block
                  text-3xl
                  col-span-12
                  mb-4
                  font-bold
                  text-gray-900
                  tracking-tight
                "
              >
                Peça ajuda
              </h1>
              <CustomInput
                label="Seu e-mail"
                name="helpEmail"
                @change="changeField"
                :value="helpEmail"
                :validation="helpEmailValidation"
                class="col-span-12"
              />
              <CustomInput
                type="phone"
                :loading="loadingFields"
                label="Telefone"
                name="helpPhone"
                :mask="['(##) ####-####', '(##) #####-####']"
                :value="helpPhone"
                @change="changeField"
                :validation="helpPhoneValidation"
                class="col-span-12"
              />
              <CustomInput
                label="Descritivo do problema"
                :optional="true"
                name="description"
                @change="changeField"
                :value="description"
                :validation="descriptionValidation"
                class="col-span-12"
              />
              <div class="w-100 flex">
                <PrimaryButton
                  @click="help"
                  :btnClass="'next'"
                  :icon="nextIcon"
                  iconPosition="right"
                  :loading="helpLoading"
                  label="Enviar"
                  class="ml-auto"
                />
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </div>
    <div class="xl:container xl:mx-auto p-8">
      <Breadcumb
        :items="!form.tecnoaccount ? items : items.slice(0, -1)"
        :index="index"
        @registerLastRequest="registerLastRequest"
      />
      <CompanyData
        v-if="index == 1"
        @cancel="cancel"
        @next="next"
        :data="form.empresa"
        :cnpjLocked="cnpjLocked"
        :clientType="clientType"
        @registerLastRequest="registerLastRequest"
        :preRegister="preRegister"
        :estabelecimentoCnpj="estabelecimentoCnpj"
        :estabelecimentoRazaoSocial="estabelecimentoRazaoSocial"
        :loading="loading"
      />
      <RepresentativeData
        v-if="index == 2"
        @back="back"
        @next="next"
        :data="form.representantes"
        :preRegister="preRegister"
        :clientType="clientType"
        :clientId="form.empresa.uuid"
        @registerLastRequest="registerLastRequest"
        :estabelecimentoCnpj="estabelecimentoCnpj"
        :cnpj_hash="cnpj_hash"
      />
      <FinancialData
        v-if="index == 3 && clientType === 'company'"
        @back="back"
        @next="next"
        :data="form.responsavel"
        :clientId="form.empresa.uuid"
        @registerLastRequest="registerLastRequest"
      />
      <TechnicalData
        v-if="index == 3 && clientType === 'software-houses'"
        @back="back"
        @next="next"
        :preRegister="preRegister"
        :data="form.tecnico"
        :clientId="form.empresa.uuid"
        @registerLastRequest="registerLastRequest"
      />
      <ValidateData
        v-if="index == 4"
        @back="back"
        @next="next"
        :form="form"
        :preRegister="preRegister"
        :protocol="protocol"
        :clientType="clientType"
        :clientId="form.empresa.uuid"
        @registerLastRequest="registerLastRequest"
      />
      <ContractData
        v-if="index == 5"
        @cadastrarEstabelecimento="cadastrarEstabelecimento"
        :CNPJ="CNPJ"
        :materaIsIdle="materaIsIdle"
        :estabelecimentoStatus="estabelecimentoStatus"
        @back="back"
        @next="next"
        :data="form"
        :companyCNPJ="estabelecimentoCnpj"
        :submitLoading="loading"
        :clientType="clientType"
        :clientId="form.empresa.uuid"
        @registerLastRequest="registerLastRequest"
        :protocolo="protocol"
      />
      <transition name="fade">
        <div class="toast flex" v-if="tecnoaccount">
          Seus dados foram sincronizados com o Tecnoaccount!
          <div @click="tecnoaccount = !tecnoaccount" class="close-button">
            x
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Breadcumb from "../ui/Breadcumb.vue";
import PrimaryButton from "../buttons/PrimaryButton";
import CompanyData from "./register/wizard/CompanyData.vue";
import RepresentativeData from "./register/wizard/RepresentativeData.vue";
import TechnicalData from "./register/wizard/TechnicalData.vue";
import FinancialData from "./register/wizard/FinancialData.vue";
import ValidateData from "./register/wizard/ValidateData.vue";
import ContractData from "./register/wizard/ContractData.vue";
import Modal from "../ui/Modal.vue";
import CustomInput from "../input/Input.vue";
import {
  faUsers,
  faGlobeAmericas,
  faUserShield,
  faCheckDouble,
  faFileContract,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import api from "@/services/api";
import utils from "@/utils";
import { email, phone } from "validacao-docs";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Create",
  props: ["data", "protocolValue", "isSameThatTheSoftwareHouse", "clientType", "CNPJ", "mediatorFee", "estabelecimentoCnpj", "cnpjLocked", "estabelecimentoRazaoSocial", "preRegister"],
  components: {
    Breadcumb,
    CompanyData,
    RepresentativeData,
    TechnicalData,
    FinancialData,
    ValidateData,
    ContractData,
    Modal,
    CustomInput,
    PrimaryButton,
    FontAwesomeIcon,
  },
  watch: {
    data: function (data) {
      if (this.isSameThatTheSoftwareHouse) {
        this.index = 1;  
      } else {
        this.index = 4;
      }
      this.form = data;
    },
  },
  async created() {
    this.formatData();
    this.protocol = this.protocolValue;
    if (this.protocolValue) {
      const notExistsAMainRepresentative = this.form.representantes?.every((representative) => !representative.isMain);
      const existsARepresentativeWithouRequiredDocuments = this.clientType === 'company' && 
        this.form.representantes?.some((representative) => !representative.selfie || (!representative.cnh && (!representative.rgFront || !representative.rgBack)));

      if (this.clientType === 'company' && !this.form.documentSocialContract) {
        this.index = 1;
      } else if (!this.form.representantes || this.form.representantes?.length === 0 || !this.form.representantes[0]?.uuid || notExistsAMainRepresentative || existsARepresentativeWithouRequiredDocuments) {
        this.index = 2;
      } else if (!this.form.tecnico?.tecnico?.uuid && !this.form.tecnico?.responsavel?.uuid && !this.form.responsavel?.uuid) {
        this.index = 3;
      } else {
        this.index = 4;        
      }
    }
  },
  data() {
    return {
      index: 1,
      form: {},
      questionCircle: faQuestionCircle,
      showHelpModal: false,
      showLoadingModal: false,
      estabelecimentoStatus: "Cadastrando representantes e responsáveis",
      helpLoaging: false,
      helpEmail: null,
      helpPhone: null,
      description: null,
      helpEmailValidation: null,
      helpPhoneValidation: null,
      descriptionValidation: null,
      protocol: null,
      cnpj_hash: null,
      loading: false,
      retrying: false,
      tecnoaccount: false,
      plugPixsuccessful: true,
      lastRequest: null,
      materaIsIdle: false,
      nextStep: true,
      items: [
        {
          label: "Dados da empresa",
          icon: faGlobeAmericas,
          index: 0,
        },
        {
          label: "Dados do representante",
          icon: faUsers,
          index: 1,
        },
        {
          label:
            this.clientType == "company"
              ? "Financeiro"
              : "Dados do técnico",
          icon: faUserShield,
          index: 2,
        },
        {
          label: "Validação",
          icon: faCheckDouble,
          index: 3,
        },
        {
          label: "Contrato",
          icon: faFileContract,
          index: 4,
        },
      ],
    };
  },
  methods: {
    async help() {
      if (!this.helpEmail || this.helpEmail.trim() == "") {
        this.helpEmailValidation = "Informe um e-mail.";
      }
      if (!email.isValidMask(this.helpEmail)) {
        this.helpEmailValidation = "O formato do e-mail não é válido.";
      } else {
        this.helpEmailValidation = null;
      }

      if (!this.helpPhone || this.helpPhone.trim() == "") {
        this.helpPhoneValidation = "Informe um telefone.";
      }
      if (!phone.isValidMask(this.helpPhone)) {
        this.helpPhoneValidation = "O formato do telefone não é válido.";
      } else {
        this.helpPhoneValidation = null;
      }

      let formPage;

      switch (this.index) {
        case 1:
          formPage = "Passo 1 - Dados da Empresa";
          break;
        case 2:
          formPage = "Passo 2 - Dados do Representante";
          break;
        case 3:
          formPage = "Passo 3 - Dados do técnico ou financeiro";
          break;
        case 4:
          formPage = "Passo 4 - Validação dos dados";
          break;
        case 5:
          formPage = "Passo 5 - Dados do Contrato";
          break;
        default:
          formPage = "Ultima Página";
          break;
      }

      const helpData = {
        url: formPage,
        dados: this.lastRequest ? this.lastRequest : {},
        email: this.helpEmail,
        telefone: this.helpPhone,
        descricao: this.description ? this.description : "",
        formulario: this.clientType,
      };

      if (
        this.helpEmailValidation == null &&
        this.helpPhoneValidation == null
      ) {
        this.cleanForm();
        await api.post("/faqs", helpData).then(() =>
          this.$swal({
            title: "Sucesso!",
            text: "Solicitação enviada.",
            confirmButtonText: "Ok",
          })
        );
      }
    },

    changeField(index, value) {
      if (index == "helpEmail") {
        this.helpEmail = value;
        if (!email.isValidMask(this.helpEmail)) {
          this.helpEmailValidation = "O formato do e-mail não é válido.";
        } else {
          this.helpEmailValidation = null;
        }
      }
      if (index == "helpPhone") {
        this.helpPhone = value;
        if (!phone.isValidMask(value)) {
          this.helpPhoneValidation = "O formato do telefone não é válido.";
        } else {
          this.helpPhoneValidation = null;
        }
      }
      if (index == "description") {
        this.description = value;
      }
    },

    async next(data) {
      this.nextStep = true;
      let token = null;

      switch (this.index) {
        case 1:
          this.loading = true;
          this.form.empresa = data.companyForm.cnpj ? utils.formatDataToForm(data.companyForm) : data.companyForm
          this.cnpj_hash = data.cnpj_hash;
          this.form.documents = data.documents
          this.loading = true;
          this.nextStep = false;
          if (this.clientType === "company") {
            await this.createCompany();
          } else {
            await this.createSoftwareHouse();
          }
          this.loading = false;
          if (this.nextStep) {
            this.index++;
          }
          break;
        case 2:
          this.loading = true;
          this.form.representantes = data.representantes[0]?.cpf ? utils.formatRepresentativeToForm(data.representantes) : data.representantes;
          this.form.representativeDocuments = data.representativeDocuments
          this.index++;

          this.loading = false;
          break;
        case 3:
          this.loading = true;
          if (this.clientType === "company") {
            this.form.responsavel = data;
          } else {
            this.form.tecnico = {};
            this.form.tecnico.responsavel = data.manager;
            this.form.tecnico.tecnico = data.technician;
          }
          await api
            .post(
              "/partners/financial",
              {
                clientType: this.clientType,
                cnpj: this.clientType === 'company' ? this.estabelecimentoCnpj : this.CNPJ,
              },              
            )
            .then((response) => {
              this.$store.commit('updateMateraAccountId', null)
              this.protocol = response.data.protocolo;
              token = response.data.access_token;
              localStorage.setItem("token", token);
            })
            .catch((response) => {
              this.lastRequest = response;
              this.$swal({
                title: "Erro!",
                icon: "error",
                text: "Os dados informados são inválidos.",
                confirmButtonText: "Ok",
              });
              this.nextStep = false;
              this.loading = false;
            });
          this.loading = false;
          if (this.nextStep) {
            this.index++;
          }
          break;
        case 4:
          this.index++;
          break;
        case 5: {
          this.loading = true;
          this.nextStep = false;
          
          if (this.clientType === "software-houses") {
            const payload = {
              retrying: this.retrying,
              cnpj: this.CNPJ,
              tecnoaccount: this.form.tecnoaccount 
              ? {
                  email: this.form.tecnoaccount.user,
                  password: this.form.tecnoaccount.password,
                }
              : undefined,           
            }
            api
              .post(
                "/partners/contract/softwarehouse",
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status == `rejected`) {
                  this.$swal({
                    title: "Erro!",
                    icon: "error",
                    allowOutsideClick: false,
                    text:
                      "Encontramos inconsistências nos seus dados, por favor entre em contato com a nossa equipe para resolver o problema.",
                    confirmButtonText: "Ok",
                  })
                  .then(() => {
                    this.$emit("cancel");
                  })
                  .catch((response) => {
                    this.lastRequest = response;
                  });
                }
                if (response.data.status == `pending-confirmation`) {
                  this.$swal({
                    title: "Atenção!",
                    icon: "warning",
                    allowOutsideClick: false,
                    text: `Enviamos um e-mail para ${this.preRegister.email} solicitando a validação do seu cadastro no tecnoaccount, por favor acesse o seu e-mail e confirme, após essa confirmação feche esta aba e clique em solicitar cadastro novamente.`,
                    confirmButtonText: "Ok",
                  })
                  .then(() => {
                    this.retrying = true;
                  })
                  .catch((response) => {
                    this.lastRequest = response;
                  });
                }

                if (response.data.status == `ok`) {        
                  this.$swal({
                    title: "Tudo Certo!",
                    icon: "success",
                    allowOutsideClick: false,
                    text:
                      "Sua conta foi sincronizada com o tecnoaccount, seu cadastro está finalizado e agora você pode cadastrar estabelecimentos comerciais vinculados a sua software house.",
                    confirmButtonText: "Acessar painel administrativo",
                  }).then(() => {
                    window.location.href = utils.getAdminUiUrl();
                  });                    
                } else {
                  this.loading = false;
                }
              })
              .catch((response) => {
                this.loading = false
                this.lastRequest = response;
                this.$swal({
                  title: "Erro!",
                  icon: "error",
                  allowOutsideClick: true,
                  text: "Houve um problema ao salvar os dados do contrato.",
                  confirmButtonText: "Ok",
                });
              });
          }
          if(this.clientType === "company" && data.contratoId){
            this.loading = true
            api
              .post(
                `/partners/finish`,
                {
                  cnpj: this.estabelecimentoCnpj,
                  softwareHouseCnpj: this.CNPJ,
                  accountId: this.$store.state.materaAccountId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then(() => {
                this.$swal({
                  title: "Tudo Certo!",
                  icon: "success",
                  text: "Sua conta foi sincronizada com o PlugPIX, seu cadastro está finalizado e agora você pode realizar movimentações financeiras usando sua chave PIX.",
                  confirmButtonText: "Finalizar",
                  allowOutsideClick: false,
                })
                .then(() => {
                  this.$router.push(`/`);
                })
                .catch((response) => {
                  this.lastRequest = response;
                })
                setTimeout(() => {this.loading = false}, 500)
              })
              .catch((response) => {
                this.loading = false
                this.lastRequest = response;
                this.$swal({
                  title: "Erro!",
                  icon: "error",
                  allowOutsideClick: true,
                  text: "Houve um problema ao enviar os dados, verifique se estão corretos e tente novamente.",
                  confirmButtonText: "Ok",
                });
                setTimeout(() => {this.loading = false}, 500)
              });
          }
          return;
        }
      }
      
      window.scrollTo(0,0);
    },

    load() {
      if (this.clientType != "software-houses") {
        this.showLoadingModal = true;
      }
    },

    formatData(){
      this.form = this.data;
      if(this.data.uuid || this.isSameThatTheSoftwareHouse) {
        this.form.empresa = utils.formatDataToForm(this.data);
        this.form.representantes = utils.formatRepresentativeToForm(this.data.representatives);
        const { tecnico, responsavel } = utils.formatResponsibleToForm(this.data.responsibles);
        this.form.tecnico = tecnico
        this.form.responsavel = responsavel;
        let materaAccount = this.data.materaAccounts?.find(account => account.status === 'regular')
        if (!materaAccount) materaAccount = this.data.materaAccounts?.find(account => account.status === 'creating')
        if(materaAccount) {
          this.$store.commit('updateMateraAccountId', materaAccount.accountId)
          this.$store.commit('updateMateraAccountStatus', materaAccount.status)
        }
      }
    },

    async createSoftwareHouse(){
      if(this.form.empresa.uuid) {
        const empresa = utils.formatFormToDatabase(this.form);
        await api
          .put(
            `/software-houses/${this.form.empresa.uuid}`,
            {
              ...empresa,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .catch((response) => {
            this.lastRequest = response;
            this.materaIsIdle = false;
            this.loading = false;
            this.showLoadingModal = false;
            this.nextStep = false;
            this.$swal({
              title: "Erro!",
              icon: "error",
              allowOutsideClick: true,
              text: "Houve um problema ao registrar software house, tente novamente.",
              confirmButtonText: "Ok",
            })
            return;
        });
        this.nextStep = true;    
        return;
      }
      const empresa = utils.formatFormToDatabase(this.form);
      let softwareHouseResponse = await api
        .post(
          `/software-houses`,
          {
            preRegisterId: this.preRegister.uuid,
            empresa,
          },
        )
        .catch((response) => {
          this.lastRequest = response;
          this.materaIsIdle = false;
          this.loading = false;
          this.showLoadingModal = false;
          this.nextStep = false;
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao registrar software house, tente novamente.",
            confirmButtonText: "Ok",
          })
          return;
        });

      this.form.empresa.uuid = softwareHouseResponse.data.uuid
      localStorage.setItem("token", softwareHouseResponse.data.access_token);
      this.nextStep = true;
    },

    async createCompany(){
      if(this.form.empresa.uuid) {
        const empresa = utils.formatFormToDatabase(this.form);

        const company = await api
          .put(
            `/company/${this.form.empresa.uuid}`,
            {
              ...empresa,
            },
          )
          .catch((response) => {
            this.lastRequest = response;
            this.materaIsIdle = false;
            this.loading = false;
            this.showLoadingModal = false;
            this.nextStep = false;
            this.$swal({
              title: "Erro!",
              icon: "error",
              allowOutsideClick: true,
              text: "Houve um problema ao registrar dados da empresa, tente novamente.",
              confirmButtonText: "Ok",
            })
            return;
          });

        this.form.empresa.socialContract = {
          fileHash: company.data.documentSocialContract
        }
        this.form.empresa.socialContractInputValue = company.data.documentSocialContract
        this.form.empresa.procuration = {
          fileHash: company.data.documentProcuration
        }
        this.form.empresa.procurationInputValue = company.data.documentProcuration
        this.nextStep = true;
        return;
      }
      const empresa = utils.formatFormToDatabase(this.form);
      let company = await api
        .post(
          `/company/${this.CNPJ}`,
          {
            preRegisterId: this.preRegister.uuid,
            empresa,
          },
        )
        .catch((response) => {
          this.lastRequest = response;
          this.materaIsIdle = false;
          this.loading = false;
          this.showLoadingModal = false;
          this.nextStep = false;
          this.$swal({
            title: "Erro!",
            icon: "error",
            allowOutsideClick: true,
            text: "Houve um problema ao registrar dados da empresa, tente novamente.",
            confirmButtonText: "Ok",
          })
          return;
        });

      this.form.empresa.uuid = company.data.uuid
      this.form.empresa.socialContract = {
        fileHash: company.data.documentSocialContract
      }
      this.form.empresa.socialContractInputValue = company.data.documentSocialContract
      this.form.empresa.procuration = {
        fileHash: company.data.documentProcuration
      }
      this.form.empresa.procurationInputValue = company.data.documentProcuration
      localStorage.setItem("token", company.data.access_token);
      this.nextStep = true;
    },


    back(data) {
      switch (this.index) {
        case 1:
          this.form.empresa = data;
          break;
        case 2:
          this.form.representantes = data;
          break;
        case 3:
          if (this.clientType == "company") {
            this.form.responsavel = data;
          } else {
            this.form.tecnico = {};
            this.form.tecnico.responsavel = data.manager;
            this.form.tecnico.tecnico = data.technician;
          }
          break;
      }

      this.index--;
      window.scrollTo(0,0);
    },

    registerLastRequest(request) {
      this.lastRequest = request;
    },

    cancel() {
      this.$emit("cancel");
    },

    cleanForm() {
      this.helpPhoneValidation = null;
      this.helpEmailValidation = null;
      this.helpLoaging = true;
      this.helpLoaging = false;
      this.showHelpModal = true;
    },

    async getMateraAccount(cnpj, accountId) {
      const materaResponse = await api
        .get(`/matera/` + cnpj + "/" + accountId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .catch((response) => {
          this.lastRequest = response;
          return null;
        });

      return materaResponse;
    },
  },
};
</script>
