<style>
.hiddenButton {
  display: none !important;
}

.showingButton {
  display: block !important;
}
</style>

<template>
  <Card title="Dados da Empresa">
    <form class="my-9 divide-y">
      <div class="grid grid-cols-12 mb-7 gap-5">
        <CustomInput :loading="loadingFields" label="CNPJ" name="CNPJ" mask="##.###.###/####-##" :autofocus="true"
          :disabled="cnpjLocked" :validation="formValidation.CNPJ" :value="companyForm.CNPJ" @change="changeField"
          class="col-span-12 sm:col-span-6 lg:col-span-3" />
        <CustomInput :loading="loadingFields" label="Razão Social" name="RazaoSocial"
          :validation="formValidation.RazaoSocial" :value="companyForm.RazaoSocial" @change="changeField"
          class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <CustomInput :loading="loadingFields" label="Nome Fantasia" name="NomeFantasia"
          :disabled="formFieldsLocked.NomeFantasia" :validation="formValidation.NomeFantasia"
          :value="companyForm.NomeFantasia" @change="changeField" class="col-span-12 sm:col-span-6 lg:col-span-5" />
        <div class="col-span-12 sm:col-span-6 xl:col-span-3"
          :class="isSoftwareHouse() ? 'lg:col-span-3' : 'lg:col-span-4'">
          <div class="flex justify-between">
            <CustomInput :loading="loadingFields" label="Inscrição Estadual" name="InscricaoEstadual"
              :disabled="companyForm.Isento" :value="companyForm.InscricaoEstadual" :clean="companyForm.Isento"
              @change="changeField" :validation="formValidation.InscricaoEstadual" class="w-full" />
            <Checkbox label="Isento" name="IsentoIE" class="ml-3" :active="companyForm.Isento" @check="lockIE" />
          </div>
        </div>
        <CustomInput :loading="loadingFields" label="E-mail Principal" name="Email" @change="changeField"
          :disabled="formFieldsLocked.Email" :value="companyForm.Email" :validation="formValidation.Email"
          class="col-span-12 sm:col-span-12" :class="isSoftwareHouse() ? 'lg:col-span-4' : 'lg:col-span-8 xl:col-span-3'
          " />
        <CustomInput :loading="loadingFields" label="Telefone" name="Telefone" :mask="[
          '(##) ####-####',
          '(##) #####-####',
        ]" :disabled="formFieldsLocked.Telefone" :value="companyForm.Telefone" type="phone" @change="changeField"
          :validation="formValidation.Telefone" class="col-span-12 sm:col-span-6" :class="isSoftwareHouse() ? 'lg:col-span-3' : 'lg:col-span-4 xl:col-span-3'
          " />
        <div class="grid col-span-12 sm:col-span-6" :class="isSoftwareHouse() ? 'lg:col-span-2' : 'lg:col-span-4 xl:col-span-3'
          ">
          <label class="input-label mb-1">Data de Abertura
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingFields"
              :icon="spinner" />
          </label>
          <Datepicker :typeable="true" v-model="companyForm.DataAbertura"
            :class="formValidation.DataAbertura ? 'border-red-500' : ''" @change="changeDate"
            :disabled="formFieldsLocked.DataAbertura" :upperLimit="new Date()" v-maska="'##/##/####'"
            inputFormat="dd/MM/y" class="col-span-12 w-full custom-input rounded-lg py-2 px-5 DataAbertura"
            :locale="locale" />
          <span class="text-red-500 text-xs DataAbertura">{{ formValidation.DataAbertura }}&nbsp;</span>
        </div>
        <CustomInput :loading="loadingFields" label="CNAE Principal" name="CNAE" mask="####-#/##" @change="changeField"
          :value="companyForm.CNAE" :validation="formValidation.CNAE" class="col-span-12 sm:col-span-6 lg:col-span-4"
          :class="isSoftwareHouse() ? ' xl:col-span-2' : 'xl:col-span-3'" />
        <CustomInput :loading="loadingFields" label="Faturamento Mensal" name="FaturamentoMensal" type="currency"
          @change="changeField" :disabled="formFieldsLocked.FaturamentoMensal" :value="companyForm.FaturamentoMensal"
          :validation="formValidation.FaturamentoMensal" class="col-span-12 sm:col-span-6 lg:col-span-4"
          :class="isSoftwareHouse() ? ' xl:col-span-2' : 'xl:col-span-3'" />
        <div v-if="isSoftwareHouse()" class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-2">
          <label class="input-label mb-1">Tipo de Atuação
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingFields"
              :icon="spinner" />
          </label>
          <Multiselect id="TipoAtuacao" trackBy="value" label="label" v-model="companyForm.TipoAtuacao" :class="formValidation.TipoAtuacao ? 'multiselect-input-invalid' : ''
          " class="col-span-12 w-full RegimeTributario" :options="atuacoes" placeholder="Selecione uma opção" />
          <span class="text-red-500 text-xs">{{ formValidation.TipoAtuacao }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
          :class="isSoftwareHouse() ? ' md:col-span-4' : 'md:col-span-6'">
          <label class="input-label mb-1">Regime Tributário
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingFields"
              :icon="spinner" />
          </label>
          <Multiselect id="RegimeTributario" trackBy="value" label="label" v-model="companyForm.RegimeTributario"
            :class="formValidation.RegimeTributario ? 'multiselect-input-invalid' : ''
          " class="col-span-12 w-full RegimeTributario" :options="regimes" placeholder="Selecione uma opção" />
          <span class="text-red-500 text-xs">{{ formValidation.RegimeTributario }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
          :class="isSoftwareHouse() ? ' md:col-span-4' : 'md:col-span-6'">
          <label class="input-label mb-1">Formato de Constituição
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary" v-if="loadingFields"
              :icon="spinner" />
          </label>
          <Multiselect id="ConstituicaoEmpresa" trackBy="value" v-model="companyForm.ConstituicaoEmpresa" :class="formValidation.ConstituicaoEmpresa
            ? 'multiselect-input-invalid'
            : ''
          " class="col-span-12 w-full ConstituicaoEmpresa" :options="formats" placeholder="Selecione uma opção" />
          <span class="text-red-500 text-xs">{{ formValidation.ConstituicaoEmpresa }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'grid col-span-12'">
          <p>Por gentileza, anexe o <b>contrato social</b> da empresa.<br /> Caso você não seja o responsável social da
            empresa, anexe também a <b>procuração</b> que lhe permite representar a empresa. (Tipo de arquivo aceito:
            <b>JPEG</b>, <b>PDF</b> e <b>PNG</b>)</p>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="Contrato Social" fileName="socialContract" name="socialContract"
            :estabelecimentoCnpj="estabelecimentoCnpj" v-model="companyForm.socialContractInputValue"
            :validation="isSoftwareHouse() ? '' : formValidation.socialContractInputValue" :value="companyForm.socialContractInputValue" @changeField="changeField" @removeFile="removeFile" />
          <span class="text-red-500 text-xs">{{ formValidation.socialContractInputValue }}&nbsp;</span>
        </div>

        <div :class="isSoftwareHouse() ? 'hidden' : 'col-span-12 sm:col-span-6 lg:col-span-3'">
          <FileUpload title="Procuração" fileName="procuration" name="procuration"
            :estabelecimentoCnpj="estabelecimentoCnpj" v-model="companyForm.procurationInputValue" :value="companyForm.procurationInputValue" @changeField="changeField" @removeFile="removeFile" />
          <span class="text-red-500 text-xs">
            {{ formValidation.procurationInputValue }}&nbsp;
          </span>
        </div>

      </div>


      <div class="grid grid-cols-12 mb-7 gap-5">
        <h1 class="inline-block text-3xl col-span-12 mt-5 font-bold text-gray-900 tracking-tight">
          Dados do Endereço
        </h1>
        <CustomInput :loading="loadingFields || loadingAddressFields" label="CEP" name="CEP" :mask="'#####-###'"
          :value="companyForm.CEP" @change="changeField" :validation="formValidation.CEP"
          class="col-span-12 sm:col-span-12 md:col-span-4 lg:col-span-2" />
        <CustomInput :loading="loadingFields || loadingAddressFields" label="Endereço" name="Endereco"
          :disabled="formFieldsLocked.Endereco" @change="changeField" :value="companyForm.Endereco"
          :validation="formValidation.Endereco" class="col-span-12 md:col-span-4 lg:col-span-5" />
        <CustomInput :loading="loadingFields || loadingAddressFields" label="Número" name="Numero"
          :value="companyForm.Numero" @change="changeField" :validation="formValidation.Numero"
          class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2" />
        <CustomInput :loading="loadingFields || loadingAddressFields" label="Complemento" name="Complemento"
          :optional="true" :value="companyForm.Complemento" @change="changeField"
          :validation="formValidation.Complemento" class="col-span-12 sm:col-span-6 lg:col-span-3" />
        <CustomInput :loading="loadingFields || loadingAddressFields" label="Bairro" name="Bairro"
          :disabled="formFieldsLocked.Bairro" @change="changeField" :value="companyForm.Bairro"
          :validation="formValidation.Bairro" class="col-span-12 sm:col-span-6 lg:col-span-4" />
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
          <label class="input-label mb-1">UF
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary"
              v-if="loadingFields || loadingAddressFields" :icon="spinner" />
          </label>
          <Multiselect id="UF" v-model="companyForm.UF" :disabled="formFieldsLocked.UF" :searchable="true"
            trackBy="sigla" noResultsText="Nenhum UF encontrado"
            :class="formValidation.UF ? 'multiselect-input-invalid' : ''" class="col-span-12 w-full UF" label="sigla"
            :options="UFs" @select="getCity" placeholder="Selecione um estado" />
          <span class="text-red-500 text-xs w-max">{{ formValidation.UF }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3">
          <label class="input-label mb-1">Cidade
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary"
              v-if="loadingFields || loadingAddressFields" :icon="spinner" />
          </label>
          <Multiselect id="Cidade" v-model="companyForm.Cidade" :disabled="formFieldsLocked.Cidade" :searchable="true"
            trackBy="nome" noOptionsText="Selecione um estado primero" noResultsText="Nenhuma cidade encontrada"
            :class="formValidation.Cidade ? 'multiselect-input-invalid' : ''" class="col-span-12 w-full Cidade"
            label="nome" :options="cities" placeholder="Selecione uma cidade" />
          <span class="text-red-500 text-xs w-max">{{ formValidation.Cidade }}&nbsp;</span>
        </div>
        <div class="grid col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-2">
          <label class="input-label mb-1">País
            <FontAwesomeIcon class="ml-4 text-black self-center animate-spin text-primary"
              v-if="loadingFields || loadingAddressFields" :icon="spinner" />
          </label>
          <Multiselect id="Pais" v-model="companyForm.Pais" :disabled="true" :searchable="true"
            noResultsText="Nenhum país encontrado" :class="formValidation.Pais ? 'multiselect-input-invalid' : ''"
            class="col-span-12 w-full Pais" label="nome" :options="['Brasil']" placeholder="Selecione um país" />
          <span class="text-red-500 text-xs w-max">{{ formValidation.Pais }}&nbsp;</span>
        </div>
      </div>
    </form>
    <div class="flex">
      <Button @click="back" :icon="backIcon" :loading="loading || loadingValidation"
        :class="{ 'custom-buttom-disabled': loading || loadingValidation }" iconPosition="left" label="Cancelar" class="ml-auto" />
      <PrimaryButton @click="next" :btnClass="'next'" :icon="nextIcon" iconPosition="right" :loading="loading || loadingValidation"
        :class="{ 'custom-buttom-disabled': loading || loadingValidation }"
        label="Prosseguir" class="ml-5" />
    </div>
  </Card>
</template>

<script>
import Card from "../../../ui/Card.vue";
import PrimaryButton from "../../../buttons/PrimaryButton";
import Button from "../../../buttons/Button";
import FileUpload from '../../../input/FileUpload';
import {
  faPlus,
  faChevronLeft,
  faChevronRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../../input/Input";
import Checkbox from "../../../input/Checkbox";
import Multiselect from "@vueform/multiselect";
import Datepicker from "vue3-datepicker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import api from "@/services/api";
import { cnpj, cep } from "validacao-docs";
import utils from "@/utils";
import pt from "date-fns/locale/pt";
import { parseISO, addMinutes } from "date-fns";

export default {
  name: "CompanyData",
  props: ["data", "clientType", "cnpjLocked", "preRegister", "estabelecimentoCnpj", "estabelecimentoRazaoSocial", "loading"],
  components: {
    Card,
    PrimaryButton,
    Multiselect,
    Button,
    CustomInput,
    Checkbox,
    Datepicker,
    FontAwesomeIcon,
    FileUpload
  },
  data() {
    return {
      locale: pt,
      spinner: faSpinner,
      selectedValue: null,
      loadingValidation: false,
      plusIcon: faPlus,
      nextIcon: faChevronRight,
      backIcon: faChevronLeft,
      companyForm: {
        RazaoSocial: null,
        NomeFantasia: null,
        Email: null,
        Telefone: null,
        DataAbertura: null,
        CNAE: null,
        FaturamentoMensal: null,
        ConstituicaoEmpresa: null,
        CNPJ: null,
        CEP: null,
        InscricaoEstadual: null,
        Numero: null,
        Bairro: null,
        Complemento: null,
        Endereco: null,
        Pais: "Brasil",
        UF: null,
        Cidade: null,
        Isento: true,
        RegimeTributario: null,
        TipoAtuacao: null,
        IBGE: null,
        hash: true,
        socialContract: null,
        procuration: null,
        socialContractInputValue: null,
        procurationInputValue: null,
      },
      shopCompany: {},
      formValidation: {},
      formFieldsLocked: {},
      UFs: [],
      cities: [],
      formats: [
        { value: "sociedade_anonima", label: "Sociedade Anônima" },
        { value: "sociedade_simples", label: "Sociedade Simples" },
        {
          value: "sociedade_empresaria_limitada",
          label: "Sociedade Empresária Limitada",
        },
        {
          value: "microempreendedor_individual",
          label: "Microempreendedor Individual",
        },
        {
          value: "empresa_individual_de_responsabilidade_limitada",
          label: "Empresa Individual de Responsabilidade Limitada",
        },
        { value: "empresa_individual", label: "Empresa Individual" },
        {
          value: "sociedade_limitada_unipessoal",
          label: "Sociedade Limitada Unipessoal",
        },
      ],
      regimes: [
        { value: "lucro_real", label: "Lucro Real" },
        { value: "lucro_presumido", label: "Lucro Presumido" },
        { value: "simples_nacional", label: "Simples Nacional" },
        { value: "mei", label: "MEI" },
      ],
      atuacoes: [
        { label: "Empresa de Software", value: "empresa_software" },
        { label: "Representante", value: "integrador_representante_software" },
        { label: "Empresa de Outro Ramo", value: "empresa_outro_ramo" },
      ],
      cnpj_hash: null,
      date: null,
      loadingFields: false,
      loadingAddressFields: false,
      documents: {
        socialContract: null,
        procuration: null
      },
    };
  },
  async created() {
    this.UFs = await utils.getUFs();
    
    if (this.data) {
      await this.getCity()
      this.loadingFields = true;
      this.companyForm = this.data;

      this.loadingFields = false;
    } else {
      if (this.preRegister.email) {
        this.companyForm.Email = this.preRegister.email
      }
      if (this.estabelecimentoCnpj) {
        this.companyForm.CNPJ = utils.applyMask("CNPJ", this.estabelecimentoCnpj);
        this.changeField("CNPJ", this.companyForm.CNPJ);
      }
      if (this.estabelecimentoRazaoSocial) {
        this.companyForm.RazaoSocial = this.estabelecimentoRazaoSocial;
      }
    }
  },
  methods: {
    changeField(index, value) {
      if (['procuration', 'socialContract'].includes(index)) {
        this.companyForm[`${index}`] = {
          fileHash: value.fileHash,
          fileName: value.fileName,
          content: value.content
        }
        this.companyForm[`${index}InputValue`] = value.fileName ? value.fileName : value.fileHash
      } else {
        this.companyForm[index] = value;
  
        if (
          utils.validate(
            index,
            this.companyForm,
            this.formValidation,
            this.clientType,
            true
          )
        ) {
          if (index == "CNPJ") {
            this.getCompanyData();
          }
          if (index == "CEP") {
            this.getAddressData();
          }
        }
      }
    },

    changeDate(event) {
      if (!utils.isValidDate(event.target.value)) {
        this.formValidation.DataAbertura = "Insira uma data válida.";
      } else {
        this.formValidation.DataAbertura = null;
      }
    },

    async next() {
      this.loadingValidation = true;
      this.formValidation = {};

      Object.keys(this.companyForm).forEach((field) => {
        field = field.replaceAll("Validated", "");
        if (this.clientType === "software-houses") {
          if (field == "procuration" || field == "socialContract" || field == "procurationInputValue" || field == "socialContractInputValue") {
            delete this.formValidation['procuration']
            delete this.formValidation['procurationInputValue']
            delete this.formValidation['socialContract']
            delete this.formValidation['socialContractInputValue']
          }
        }

        if (field === "TipoAtuacao" && this.clientType === "software-houses") {
          if (!this.companyForm[field]) {
            this.formValidation[field] = "Este campo é obrigatório.";
          }
        }

        if (
          (!this.companyForm[field] || this.companyForm[field] === "") &&
          field != "Complemento" &&
          field != "Isento" &&
          field != "TipoAtuacao" &&
          field != "procuration" &&
          field != "procurationInputValue"
        ) {
          if (field != "InscricaoEstadual") {
            this.formValidation[field] = "Este campo é obrigatório.";
          } else {
            if (!this.companyForm.Isento) {
              this.formValidation[field] = "Este campo é obrigatório.";
            }
          }
        }
        utils.validate(
          field,
          this.companyForm,
          this.formValidation,
          this.clientType
        );
      });

      if (Object.keys(this.formValidation).length > 1) {
        this.loadingValidation = false;
        return;
      }

      this.loadingValidation = false;

      this.$emit("next", {
        companyForm: this.companyForm,
        cnpj_hash: this.cnpj_hash
      });
    },

    back() {
      this.$emit("cancel");
    },

    async removeFile(fileName) {
      const oldFileData = this.companyForm[fileName];
      const oldFileInputValue = this.companyForm[`${fileName}InputValue`];
      try {
        this.companyForm[fileName] = {}
        this.companyForm[`${fileName}InputValue`] = ''
        if (this.companyForm.uuid) {
          await api.delete(`/company/${this.companyForm.uuid}/documents/${fileName}`);
        }
      } catch(error) {
        this.companyForm[fileName] = oldFileData
        this.companyForm[`${fileName}InputValue`] = oldFileInputValue
        this.$swal({
          title: "Erro!",
          icon: "error",
          allowOutsideClick: true,
          text: "Houve um problema ao remover o arquivo, tente novamente.",
          confirmButtonText: "Ok",
        })
      }
    },

    async getCompanyData() {
    this.loadingFields = true;

    try {
        if (this.companyForm.uuid) {
            this.loadingFields = false;
            return;
        }
        const shopDataResponse = await this.getSHOPData(
            this.companyForm.CNPJ.replaceAll("/", "")
                .replaceAll(".", "")
                .replaceAll("-", "")
        );

        if (!shopDataResponse) {
            const cnpjData = await cnpj.getData(this.companyForm.CNPJ);

            if (cnpjData) {
                if (!this.data?.email && !this.preRegister.email) {
                  this.companyForm.Email = cnpjData.email;
                }
                if (!this.preRegister.corporateName) {
                  this.companyForm.RazaoSocial = cnpjData.corporateName;
                }
                  
                this.companyForm.NomeFantasia = cnpjData.alias;

                let formattedPhone = cnpjData.phone;
                if (formattedPhone && !formattedPhone.startsWith("0800")) {
                    if (formattedPhone.charAt(5) === "9" || formattedPhone.charAt(5) === "8") {
                        formattedPhone = formattedPhone.substring(0, 4) + " 9" + formattedPhone.substring(5, 14);
                    }
                    this.companyForm.Telefone = "+55 " + formattedPhone;
                }

                const openingDate = parseISO(cnpjData.founded);
                const adjustedOpeningDate = addMinutes(openingDate, openingDate.getTimezoneOffset());
                this.companyForm.DataAbertura = adjustedOpeningDate;
                this.companyForm.FaturamentoMensal = String(cnpjData.capital * 100);
                this.companyForm.CNAE = `${cnpjData.primary_activity.code.substr(0, 4)}-${cnpjData.primary_activity.code.substr(4, 1)}/${cnpjData.primary_activity.code.substr(5, 2)}`;

                if (!this.companyForm.IBGE) {
                    const cepData = await cep.getData(cnpjData.address.zip.replaceAll("-", ""));
                    if (cepData) {
                        this.companyForm.IBGE = cepData.ibge;
                    }
                }

                this.companyForm.CEP = `${cnpjData.address.zip.substr(0, 5)}-${cnpjData.address.zip.substr(5, 3)}`;
                this.formFieldsLocked.CEP = !cnpjData.address.zip;
                this.companyForm.UF = cnpjData.address.state;
                this.formFieldsLocked.UF = !this.companyForm.UF;
                this.companyForm.Cidade = cnpjData.address.city;
                this.formFieldsLocked.Cidade = !this.companyForm.Cidade;
                await this.getAddressData();
                this.companyForm.Numero = cnpjData.address.number;
                this.formFieldsLocked.Numero = !this.companyForm.Numero;
                this.companyForm.Endereco = cnpjData.address.street;
                this.formFieldsLocked.Endereco = !this.companyForm.Endereco;
                this.companyForm.Complemento = cnpjData.address.details;
                this.formFieldsLocked.Complemento = !this.companyForm.Complemento;
                this.companyForm.Bairro = cnpjData.address.neighborhood;
                this.formFieldsLocked.Bairro = !this.companyForm.Bairro;
            }
        }
    } catch (error) {
        this.$emit("registerLastRequest", error);
    } finally {
        this.loadingFields = false;
    }
},


    async getSHOPData(cnpj) {
      const this_ = this;
      let returnValue = false;
      const disponibilidadeResponse = await api.get(
        `/${this.clientType}/disponibilidade/` + cnpj
      );
      const url = `/` + this.clientType + `/` + cnpj;
      if (disponibilidadeResponse.data.disponivel) {
        return await api
          .get(url)
          .then((response) => {
            if (!response.data.hash) return false;
            this.cnpj_hash = response.data.hash;

            const interval = setInterval(async function () {
              await api
                .get(`/hashes/` + response.data.hash)
                .then(async (response) => {
                  if (response.data) {
                    const dados = response.data.data;
                    clearInterval(interval);
                    document
                      .getElementsByClassName("hiddenButton")[0]
                      .classList.add("showingButton");
                    document
                      .getElementsByClassName("cancel-btn")[0]
                      .classList.add("hiddenButton");
                    document
                      .getElementsByClassName("sk-fading-circle")[0]
                      .classList.add("hiddenButton");
                    document
                      .getElementsByClassName("custom-confirm-message")[0]
                      .classList.add("hiddenButton");
                    this_.companyForm.FaturamentoMensal = String(
                      dados.monthlyIncome * 100
                    );
                    if (!this_.preRegister.corporateName) {
                      this_.companyForm.RazaoSocial = dados.legalName;
                    }
                    this_.companyForm.NomeFantasia = dados.tradingName;
                    this_.companyForm.CNAE = dados.cnae;
                    this_.companyForm.TipoAtuacao = dados.fieldOfActivity;
                    this_.companyForm.ConstituicaoEmpresa = dados.constitution;
                    this_.companyForm.RegimeTributario = dados.taxRegime;
                    const openingDate = parseISO(dados.openingDate);
                    const adjustedOpeningDate = addMinutes(
                      openingDate,
                      openingDate.getTimezoneOffset()
                    );
                    this_.companyForm.DataAbertura = new Date(
                      adjustedOpeningDate
                    );
                    dados.person.contacts.forEach((contact) => {
                      if (contact.category == "principal") {
                        if (!this_.preRegister.email) {
                          this_.companyForm.Email = contact.email;
                        }
                        const phone = contact.phone.split(' ').join('');
                        if (phone && !phone.startsWith("0800")) {
                          this_.companyForm.Telefone = utils.applyMask('Telefone', '+55 ' + phone);
                        }
                      }
                    });
                    const personAddress = dados.person.personAddresses?.find((address) => address.type === 'principal');
                    const endereco = personAddress?.address
                    if (endereco) {
                      this_.companyForm.CEP = `${endereco.zipCode.substr(0, 5)}-${endereco.zipCode.substr(5, 3)}`;
                      this_.formFieldsLocked.CEP = !endereco.zipCode;
                      this_.companyForm.UF = endereco.uf;
                      this_.formFieldsLocked.UF = !this_.companyForm.UF;
                      this_.companyForm.Cidade = endereco.city;
                      this_.formFieldsLocked.Cidade = !this_.companyForm.Cidade;
                      await this_.getAddressData();
                      this_.companyForm.Numero = endereco.number;
                      this_.formFieldsLocked.Numero = !endereco.number;
                      this_.companyForm.Endereco = endereco.complement;
                      this_.formFieldsLocked.Endereco = this_.companyForm.Endereco;
                      this_.companyForm.Complemento = endereco.complement;
                      this_.formFieldsLocked.Complemento = !this_.companyForm.Complemento;
                      this_.companyForm.Bairro = endereco.neighborhood;
                      this_.formFieldsLocked.Bairro = !this_.companyForm.Bairro;

                    }
                    this_.loadingFields = false;
                    returnValue = true;
                  }
                })
                .catch((response) => {
                  this.$emit("registerLastRequest", response);
                });
            }, 3000);
            return this.$swal({
              title: "Este CNPJ já consta em nossa base!",
              html:
                `Foi enviado um e-mail para ${response.data.email} solicitando a confirmação para continuar o cadastro, assim que confirmado você poderá continuar o cadastro por aqui! É importante para a continuidade do cadastro que <b>ESTA PÁGINA CONTINUE ABERTA.</b><br/>` +
                '<div class="sk-fading-circle">' +
                '<div class="sk-circle1 sk-circle"></div>' +
                '<div class="sk-circle2 sk-circle"></div>' +
                '<div class="sk-circle3 sk-circle"></div>' +
                '<div class="sk-circle4 sk-circle"></div>' +
                '<div class="sk-circle5 sk-circle"></div>' +
                '<div class="sk-circle6 sk-circle"></div>' +
                '<div class="sk-circle7 sk-circle"></div>' +
                '<div class="sk-circle8 sk-circle"></div>' +
                '<div class="sk-circle9 sk-circle"></div>' +
                '<div class="sk-circle10 sk-circle"></div>' +
                '<div class="sk-circle11 sk-circle"></div>' +
                '<div class="sk-circle12 sk-circle"></div>' +
                '</div><div class="custom-confirm-message">Aguardando a confirmação do uso dos dados...</div>',
              confirmButtonText: "Continuar",
              cancelButtonText: "Cancelar",
              allowOutsideClick: false,
              showConfirmButton: true,
              showCancelButton: true,
              customClass: {
                confirmButton: "hiddenButton",
                cancelButton: "cancel-btn",
              },
            }).then(() => {
              clearInterval(interval);
              return returnValue;
            });
          })
          .catch((response) => {
            this.$emit("registerLastRequest", response);
          });
      } else {
        return false;
      }
    },

    async getAddressData() {
      this.loadingAddressFields = true;
      const response = await cep
        .getData(this.companyForm.CEP.replaceAll("-", ""))
        .catch((response) => {
          this.$emit("registerLastRequest", response);
        });

      if (response) {
        this.companyForm.IBGE = response.ibge;
        this.companyForm.Endereco = response.logradouro;
        this.formFieldsLocked.Endereco = !response.logradouro ? false : true;
        this.companyForm.Bairro = response.bairro;
        this.formFieldsLocked.Bairro = !response.bairro ? false : true;
        this.companyForm.UF = response.uf;
        this.formFieldsLocked.UF = !this.companyForm.UF ? false : true;
        this.cities = [response.localidade];
        this.companyForm.Cidade = response.localidade;
        this.formFieldsLocked.Cidade = !this.companyForm.Cidade
          ? false
          : true;
        this.companyForm.Numero = '';
        this.companyForm.Complemento = '';
      }

      this.loadingAddressFields = false;
    },

    async getCity() {
      if (this.companyForm.UF) {
        if (!this.companyForm.Isento) {
          utils.validate(
            "InscricaoEstadual",
            this.companyForm,
            this.formValidation,
            this.clientType
          );
        }
        this.companyForm.Cidade = null;
        this.cities = await utils.getCitiesByUF(this.companyForm.UF).catch((response) => {
          this.$emit("registerLastRequest", response);
        });
      } else if (this.data && this.data.UF) {
        this.cities = await utils.getCitiesByUF(this.data.UF).catch((response) => {
          this.$emit("registerLastRequest", response);
        });
      }
    },

    lockIE() {
      this.companyForm.Isento = !this.companyForm.Isento;
      this.formValidation.InscricaoEstadual = null;
    },

    isSoftwareHouse() {
      return this.clientType == "software-houses";
    },
  },
};
</script>
